import React, {useEffect, useState} from "react";
import {MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBView, MDBContainer} from "mdbreact";
import header from "../../assets/headers/header1.jpg"
import IdabChart from "./IdabChart";
import IdabInfoMap from "./IdabInfoMap";
import IdabInfoNumbers from "./IdabInfoNumbers";
import IdabInfoPercentage from "./IdabInfoPercentage";
import image1 from "../../assets/mission.jpg";
import {connect} from "react-redux";

const AboutSection = ({page}) => {

  const [data, setData] = useState(null)

  useEffect(() =>{
    if(Array.isArray(page) && page.length>0){
      setData(page?.filter(item => item.title === 'О нас')[0])
    }
  },[page])

  console.log(page)
  console.log(page?.filter(item => item.title === 'О нас')[0])

  return (
    <React.Fragment>
      <MDBRow className="">
        <MDBCol md="12">

          <div className='px-5'>
            <div
              className="z-depth-2 position-relative"
              data-test="view"
              style={{
                backgroundColor: '#ffffff',
                // backgroundPosition: 'center',
                height: 'auto',
                zIndex: '2',
                borderRadius: '.25rem',
                // boxShadow: '0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)',
              }}
            >

              <div
              >

                <MDBRow className='p-5'>
                <MDBCol lg='4' className='d-flex justify-content-center align-items-center'>
                  <IdabChart/>
                </MDBCol>
                <MDBCol lg='4' className='d-flex justify-content-center align-items-center'>
                  <IdabInfoMap/>
                </MDBCol>
                <MDBCol lg='4' className='d-flex justify-content-center align-items-center'>
                  <IdabInfoPercentage/>
                </MDBCol>
              </MDBRow>

              </div>
            </div>
          </div>

          <div
            className='z-depth-1 idab'
            style={{
              width: '100vw',
              padding: '4rem',
              margin: '-1rem'
            }}
          >
            <h2 className="white-text text-center font-weight-bolder">
                О нас
              </h2>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBContainer className='mb-5'>
        <MDBCard className='p-5'>
          <MDBCardBody>
            <div
              dangerouslySetInnerHTML={{__html: data?.body}}
            />
          </MDBCardBody>

        </MDBCard>
      </MDBContainer>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  page: state.pages.pages
})

export default connect(mapStateToProps)(AboutSection);