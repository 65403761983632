import React from 'react';
import {MDBRow} from "mdbreact";

const EventsLayout = ({children}) => {

  return (
    <div style={{paddingTop: 148}}>

      {children}

    </div>
  );
}

export default EventsLayout;
