import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard, MDBLink,
} from "mdbreact";
import {connect} from 'react-redux'

const HomeBlog = ({blog}) => {

  const createMarkup = (html) => {
    return {__html: html};
  }

  return (
    <div className='idab-2 w-100 z-depth-1'>
      <MDBContainer className='md-accordion py-5'>
        <div className="home-titles">
          <h2>Блог</h2>
        </div>
        <MDBRow className='mt-3'>
          <MDBCard className='flex-column flex-md-row align-items-center align-items-md-start pt-5 pt-md-0'>
            <div>
              <img src={blog.image} alt="" width="250px"/>
            </div>
            <div style={{margin: '30px'}}>
              <div dangerouslySetInnerHTML={createMarkup(blog.sub2)}/>
              <div dangerouslySetInnerHTML={createMarkup(blog.sub1)} style={{fontWeight: '600'}}/>
              <div>
                <div dangerouslySetInnerHTML={createMarkup(blog.excerption)}/>
                <MDBLink to={'/blog'}>Читать далее</MDBLink>
              </div>
            </div>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  blog: state.home.home_blog
})

export default connect(mapStateToProps)(HomeBlog);