import React, {useEffect, useRef, useState} from 'react';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import HomeProgramBlock from "../home/HomeProgramBlock";
import MetaTags from 'react-meta-tags';
import {connect} from "react-redux";
import {load_categories, load_mba} from '../../redux/actions/programs'
import {useHistory} from "react-router-dom";
import ProgramBlock from "./ProgramBlock";

const ProgramsSection = ({mba, programs, page, load_categories, load_mba, error}) => {

  const history = useHistory()

  useEffect(() => {
    if(error) {
      history.push('/404')
    }
  }, [error])

  useEffect(() => {
    load_categories();
    load_mba();
  }, [])

  const image_block = useRef(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [image, setImage] = useState('')

  useEffect(() => {
    if (image_block.current) {
      setWidth(image_block.current.offsetWidth)
      if (width > 0) {
        setHeight(width / 3)
      }
    }
  })

  useEffect(() => {
    image_block.current.height = height
  }, [height])

  useEffect(() => {
    if (page) {
      setImage(page.image)
    }
  }, [page])

  return (
    <React.Fragment>
      <MDBRow className="">
        <MDBCol md="12">

          <div className='px-5'>
            <div
              ref={image_block}
              className="z-depth-2 position-relative"
              data-test="view"
              style={{
                backgroundImage: 'url(' + image + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: height,
                zIndex: '2',
                borderRadius: '.25rem',
                // boxShadow: '0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)',
              }}
            >

              <div
                data-test="container"
                className="container position-absolute h-100"
              >

                <MDBRow className='mx-5 h-100'>
                  <MDBCol size='8' className='d-flex align-items-center justify-content-center'>
                    <div className='d-flex flex-column'>


                    </div>
                  </MDBCol>
                  <MDBCol size='4'></MDBCol>
                </MDBRow>

              </div>
            </div>
          </div>

          <div
            className='z-depth-1 idab'
            style={{
              width: '100vw',
              padding: '4rem',
              margin: '-1rem 0'
            }}
          >
            <h2 className="white-text text-center font-weight-bolder">
              Программы института
            </h2>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBContainer>
        <MDBRow className="d-flex flex-row">

          {programs?.length>0 && programs?.map((item) => (
            <ProgramBlock key={item.id} item={item} mba={mba}/>
          ))}

        </MDBRow>
      </MDBContainer>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  programs: state.programs.categories,
  mba: state.programs.mba,
  page: state.pages.pages[0],
  error: state.programs.error,
});

export default connect(mapStateToProps,{load_categories, load_mba})(ProgramsSection);