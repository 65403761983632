//Home Page actions
export const TRIGGER_SET_TO_TRUE = 'TRIGGER_SET_TO_TRUE';
export const TRIGGER_SET_TO_FALSE = 'TRIGGER_SET_TO_FALSE';
export const GET_SECTION_HEIGHT_SUCCESS = 'GET_SECTION_HEIGHT_SUCCESS';
export const GET_SECTION_HEIGHT_FAIL = 'GET_SECTION_HEIGHT_FAIL';
export const GET_NAVBAR_HEIGHT_SUCCESS = 'GET_NAVBAR_HEIGHT_SUCCESS';
export const GET_NAVBAR_HEIGHT_FAIL = 'GET_NAVBAR_HEIGHT_FAIL';
export const GET_HOME_TESTIMONIALS_SUCCESS = 'GET_HOME_TESTIMONIALS_SUCCESS';
export const GET_HOME_TESTIMONIALS_FAIL = 'GET_HOME_TESTIMONIALS_FAIL';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';
export const GET_HOME_FAQ_SUCCESS = 'GET_HOME_FAQ_SUCCESS';
export const GET_HOME_FAQ_FAIL = 'GET_HOME_FAQ_FAIL';
export const GET_HOME_BLOG_SUCCESS = 'GET_HOME_BLOG_SUCCESS';
export const GET_HOME_BLOG_FAIL = 'GET_HOME_BLOG_FAIL';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAIL = 'GET_CONTACTS_FAIL';
export const GET_ABOUT_MANAGEMENT_SUCCESS = 'GET_ABOUT_MANAGEMENT_SUCCESS';
export const GET_ABOUT_MANAGEMENT_FAIL = 'GET_ABOUT_MANAGEMENT_FAIL';
export const GET_ABOUT_LEADER_SUCCESS = 'GET_ABOUT_LEADER_SUCCESS';
export const GET_ABOUT_LEADER_FAIL = 'GET_ABOUT_LEADER_FAIL';
export const GET_ABOUT_TEACHERS_SUCCESS = 'GET_ABOUT_TEACHERS_SUCCESS';
export const GET_ABOUT_TEACHERS_FAIL = 'GET_ABOUT_TEACHERS_FAIL';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';
export const GET_MBA_SUCCESS = 'GET_MBA_SUCCESS';
export const GET_MBA_FAIL = 'GET_MBA_FAIL';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
export const GET_PROGRAM_FAIL = 'GET_PROGRAM_FAIL';
export const RESET_ERROR = 'RESET_ERROR';
export const SEND_PROGRAM_BID_SUCCESS = 'SEND_PROGRAM_BID_SUCCESS';
export const SEND_PROGRAM_BID_FAIL = 'SEND_PROGRAM_BID_FAIL';
export const SEND_PLAN_BID_SUCCESS = 'SEND_PLAN_BID_SUCCESS';
export const SEND_PLAN_BID_FAIL = 'SEND_PLAN_BID_FAIL';
export const SEND_EDUCATION_BID_SUCCESS = 'SEND_EDUCATION_BID_SUCCESS';
export const SEND_EDUCATION_BID_FAIL = 'SEND_EDUCATION_BID_FAIL';
export const GET_SINGLE_EVENT_SUCCESS = 'GET_SINGLE_EVENT_SUCCESS';
export const GET_SINGLE_EVENT_FAIL = 'GET_SINGLE_EVENT_FAIL';
export const SEND_EVENT_BID_SUCCESS = 'SEND_EVENT_BID_SUCCESS';
export const SEND_EVENT_BID_FAIL = 'SEND_EVENT_BID_FAIL';
export const GET_ABOUT_GALLERY_SUCCESS = 'GET_ABOUT_GALLERY_SUCCESS';
export const GET_ABOUT_GALLERY_FAIL = 'GET_ABOUT_GALLERY_FAIL';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_FAIL = 'GET_PAGES_FAIL';
export const GET_SUBSECTIONS_SUCCESS = 'GET_SUBSECTIONS_SUCCESS';
export const GET_SUBSECTIONS_FAIL = 'GET_SUBSECTIONS_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const GET_LK_CATEGORIES_SUCCESS = 'GET_LK_CATEGORIES_SUCCESS';
export const GET_LK_CATEGORIES_FAIL = 'GET_LK_CATEGORIES_FAIL';
export const GET_SINGLE_LK_CATEGORY_SUCCESS = 'GET_SINGLE_LK_CATEGORY_SUCCESS';
export const GET_SINGLE_LK_CATEGORY_FAIL = 'GET_SINGLE_LK_CATEGORY_FAIL';
export const SET_LK_CATEGORIES_SUCCESS = 'SET_LK_CATEGORIES_SUCCESS';
export const SET_LK_CATEGORIES_FAIL = 'SET_LK_CATEGORIES_FAIL';
export const UPDATE_LK_CATEGORIES_SUCCESS = 'UPDATE_LK_CATEGORIES_SUCCESS';
export const UPDATE_LK_CATEGORIES_FAIL = 'UPDATE_LK_CATEGORIES_FAIL';
export const DELETE_LK_CATEGORIES_SUCCESS = 'DELETE_LK_CATEGORIES_SUCCESS';
export const DELETE_LK_CATEGORIES_FAIL = 'DELETE_LK_CATEGORIES_FAIL';
export const GET_LK_COURSES_SUCCESS = 'GET_LK_CATEGORIES_SUCCESS';
export const GET_LK_COURSES_FAIL = 'GET_LK_CATEGORIES_FAIL';
export const GET_SINGLE_LK_COURSE_SUCCESS = 'GET_SINGLE_LK_CATEGORY_SUCCESS';
export const GET_SINGLE_LK_COURSE_FAIL = 'GET_SINGLE_LK_CATEGORY_FAIL';
export const SET_LK_COURSES_SUCCESS = 'SET_LK_CATEGORIES_SUCCESS';
export const SET_LK_COURSES_FAIL = 'SET_LK_CATEGORIES_FAIL';
export const UPDATE_LK_COURSES_SUCCESS = 'UPDATE_LK_CATEGORIES_SUCCESS';
export const UPDATE_LK_COURSES_FAIL = 'UPDATE_LK_CATEGORIES_FAIL';
export const DELETE_LK_COURSES_SUCCESS = 'DELETE_LK_CATEGORIES_SUCCESS';
export const DELETE_LK_COURSES_FAIL = 'DELETE_LK_CATEGORIES_FAIL';
export const GET_LK_COURSES_BLOCKS_SUCCESS = 'GET_LK_COURSES_BLOCKS_SUCCESS';
export const GET_LK_COURSES_BLOCKS_FAIL = 'GET_LK_COURSES_BLOCKS_FAIL';
export const GET_SINGLE_LK_COURSES_BLOCK_SUCCESS = 'GET_SINGLE_LK_COURSES_BLOCK_SUCCESS';
export const GET_SINGLE_LK_COURSES_BLOCK_FAIL = 'GET_SINGLE_LK_COURSES_BLOCK_FAIL';
export const SET_LK_COURSES_BLOCKS_SUCCESS = 'SET_LK_COURSES_BLOCKS_SUCCESS';
export const SET_LK_COURSES_BLOCKS_FAIL = 'SET_LK_COURSES_BLOCKS_FAIL';
export const UPDATE_LK_COURSES_BLOCKS_SUCCESS = 'UPDATE_LK_COURSES_BLOCKS_SUCCESS';
export const UPDATE_LK_COURSES_BLOCKS_FAIL = 'UPDATE_LK_COURSES_BLOCKS_FAIL';
export const DELETE_LK_COURSES_BLOCKS_SUCCESS = 'DELETE_LK_COURSES_BLOCKS_SUCCESS';
export const DELETE_LK_COURSES_BLOCKS_FAIL = 'DELETE_LK_COURSES_BLOCKS_FAIL';
export const ADD_ROW_ID = 'ADD_ROW_ID';
export const DELETE_ROW_ID = 'DELETE_ROW_ID';
export const ADD_ALL_IDS = 'ADD_ALL_IDS';
export const DELETE_ALL_IDS = 'DELETE_ALL_IDS';
export const GET_ALL_STUDENTS_LIST_SUCCESS = 'GET_ALL_STUDENTS_LIST_SUCCESS';
export const GET_ALL_STUDENTS_LIST_FAIL = 'GET_ALL_STUDENTS_LIST_FAIL';
export const GET_ALL_STUDENTS_ID_FAIL = 'GET_ALL_STUDENTS_ID_FAIL';
export const STUDENT_UPDATE_SUCCESS = 'STUDENT_UPDATE_SUCCESS';
export const STUDENT_UPDATE_FAIL = 'STUDENT_UPDATE_FAIL';
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS';
export const STUDENT_DELETE_FAIL = 'STUDENT_DELETE_FAIL';
export const STUDENT_ADD_SUCCESS = 'STUDENT_ADD_SUCCESS';
export const STUDENT_ADD_FAIL = 'STUDENT_ADD_FAIL';
export const STUDENTS_ID_LIST_ADD = 'STUDENTS_ID_LIST_ADD';
export const STUDENTS_ID_LIST_REMOVE = 'STUDENTS_ID_LIST_REMOVE';
export const STUDENTS_ID_LIST_ADD_ALL = 'STUDENTS_ID_LIST_ADD_ALL';
export const STUDENTS_ID_LIST_REMOVE_ALL = 'STUDENTS_ID_LIST_REMOVE_ALL';
export const GET_SPECIALITIES_SUCCESS = 'GET_SPECIALITIES_SUCCESS';
export const GET_SPECIALITIES_FAIL = 'GET_SPECIALITIES_FAIL';
export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS_SUCCESS';
export const GET_PROGRAMS_FAIL = 'GET_PROGRAMS_FAIL';
export const GET_ALL_TEACHERS_LIST_SUCCESS = 'GET_ALL_TEACHERS_LIST_SUCCESS';
export const GET_ALL_TEACHERS_LIST_FAIL = 'GET_ALL_TEACHERS_LIST_FAIL';
export const GET_ALL_TEACHERS_ID_FAIL = 'GET_ALL_TEACHERS_ID_FAIL';
export const TEACHER_ADD_SUCCESS = 'TEACHER_ADD_SUCCESS';
export const TEACHER_ADD_FAIL = 'TEACHER_ADD_FAIL';
export const TEACHER_UPDATE_SUCCESS = 'TEACHER_UPDATE_SUCCESS';
export const TEACHER_UPDATE_FAIL = 'TEACHER_UPDATE_FAIL';
export const TEACHER_DELETE_SUCCESS = 'TEACHER_DELETE_SUCCESS';
export const TEACHER_DELETE_FAIL = 'TEACHER_DELETE_FAIL';
export const TEACHERS_ID_LIST_ADD = 'TEACHERS_ID_LIST_ADD';
export const TEACHERS_ID_LIST_REMOVE = 'TEACHERS_ID_LIST_REMOVE';
export const TEACHERS_ID_LIST_ADD_ALL = 'TEACHERS_ID_LIST_ADD_ALL';
export const TEACHERS_ID_LIST_REMOVE_ALL = 'TEACHERS_ID_LIST_REMOVE_ALL';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAIL = 'GET_GROUPS_FAIL';
export const SORT_STUDENTS_SUCCESS = 'SORT_STUDENTS_SUCCESS'
export const SORT_STUDENTS_FAIL = 'SORT_STUDENTS_FAIL'
export const GET_LK_PROGRAMS_SUCCESS = 'GET_LK_PROGRAMS_SUCCESS'
export const GET_LK_PROGRAMS_FAIL = 'GET_LK_PROGRAMS_FAIL'
export const GET_LK_FILTERED_PROGRAMS_SUCCESS = 'GET_LK_FILTERED_PROGRAMS_SUCCESS'
export const SET_LK_PROGRAM_SUCCESS = 'SET_LK_PROGRAM_SUCCESS'
export const SET_LK_PROGRAM_FAIL = 'SET_LK_PROGRAM_FAIL'
export const UPDATE_LK_PROGRAM_SUCCESS = 'UPDATE_LK_PROGRAM_SUCCESS'
export const UPDATE_LK_PROGRAM_FAIL = 'UPDATE_LK_PROGRAM_FAIL'
export const DELETE_LK_PROGRAM_SUCCESS = 'DELETE_LK_PROGRAM_SUCCESS'
export const DELETE_LK_PROGRAM_FAIL = 'DELETE_LK_PROGRAM_FAIL'
export const GET_BASIC_GROUPS_SUCCESS = 'GET_BASIC_GROUPS_SUCCESS'
export const GET_BASIC_GROUPS_FAIL = 'GET_BASIC_GROUPS_FAIL'
export const SET_BASIC_GROUP_SUCCESS = 'SET_BASIC_GROUP_SUCCESS'
export const SET_BASIC_GROUP_FAIL = 'SET_BASIC_GROUP_FAIL'
export const UPDATE_BASIC_GROUP_SUCCESS = 'UPDATE_BASIC_GROUP_SUCCESS'
export const UPDATE_BASIC_GROUP_FAIL = 'UPDATE_BASIC_GROUP_FAIL'
export const DELETE_BASIC_GROUP_SUCCESS = 'DELETE_BASIC_GROUP_SUCCESS'
export const DELETE_BASIC_GROUP_FAIL = 'DELETE_BASIC_GROUP_FAIL'
export const GET_LK_GROUPS_SUCCESS = 'GET_LK_GROUPS_SUCCESS'
export const GET_LK_GROUPS_FAIL = 'GET_LK_GROUPS_FAIL'
export const SET_GROUP_SUCCESS = 'SET_GROUP_SUCCESS'
export const SET_GROUP_FAIL = 'SET_GROUP_FAIL'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL'
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS'
export const GET_SCHEDULE_FAIL = 'GET_SCHEDULE_FAIL'
export const SET_SCHEDULE_SUCCESS = 'SET_SCHEDULE_SUCCESS'
export const SET_SCHEDULE_FAIL = 'SET_SCHEDULE_FAIL'
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS'
export const UPDATE_SCHEDULE_FAIL = 'UPDATE_SCHEDULE_FAIL'
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS'
export const DELETE_SCHEDULE_FAIL = 'DELETE_SCHEDULE_FAIL'
export const GET_LIBRARY_SUCCESS = 'GET_LIBRARY_SUCCESS'
export const GET_LIBRARY_FAIL = 'GET_LIBRARY_FAIL'
export const SET_LIBRARY_ITEM_SUCCESS = 'SET_LIBRARY_ITEM_SUCCESS'
export const SET_LIBRARY_ITEM_FAIL = 'SET_LIBRARY_ITEM_FAIL'
export const UPDATE_LIBRARY_ITEM_SUCCESS = 'UPDATE_LIBRARY_ITEM_SUCCESS'
export const UPDATE_LIBRARY_ITEM_FAIL = 'UPDATE_LIBRARY_ITEM_FAIL'
export const DELETE_LIBRARY_ITEM_SUCCESS = 'DELETE_LIBRARY_ITEM_SUCCESS'
export const DELETE_LIBRARY_ITEM_FAIL = 'DELETE_LIBRARY_ITEM_FAIL'
export const GET_STUDY_MATERIALS_SUCCESS = 'GET_STUDY_MATERIALS_SUCCESS'
export const GET_STUDY_MATERIALS_FAIL = 'GET_STUDY_MATERIALS_FAIL'
export const SET_STUDY_MATERIAL_SUCCESS = 'SET_STUDY_MATERIAL_SUCCESS'
export const SET_STUDY_MATERIAL_FAIL = 'SET_STUDY_MATERIAL_FAIL'
export const UPDATE_STUDY_MATERIAL_SUCCESS = 'UPDATE_STUDY_MATERIAL_SUCCESS'
export const UPDATE_STUDY_MATERIAL_FAIL = 'UPDATE_STUDY_MATERIAL_FAIL'
export const DELETE_STUDY_MATERIAL_SUCCESS = 'DELETE_STUDY_MATERIAL_SUCCESS'
export const DELETE_STUDY_MATERIAL_FAIL = 'DELETE_STUDY_MATERIAL_FAIL'
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL'
export const SET_TASK_SUCCESS = 'SET_TASK_SUCCESS'
export const SET_TASK_FAIL = 'SET_TASK_FAIL'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL'
export const GET_SOLUTIONS_SUCCESS = 'GET_SOLUTIONS_SUCCESS'
export const GET_SOLUTIONS_FAIL = 'GET_SOLUTIONS_FAIL'
export const SET_SOLUTION_SUCCESS = 'SET_SOLUTION_SUCCESS'
export const SET_SOLUTION_FAIL = 'SET_SOLUTION_FAIL'
export const UPDATE_SOLUTION_SUCCESS = 'UPDATE_SOLUTION_SUCCESS'
export const UPDATE_SOLUTION_FAIL = 'UPDATE_SOLUTION_FAIL'
export const DELETE_SOLUTION_SUCCESS = 'DELETE_SOLUTION_SUCCESS'
export const DELETE_SOLUTION_FAIL = 'DELETE_SOLUTION_FAIL'
export const GET_ATTENDANCE_SUCCESS = 'GET_ATTENDANCE_SUCCESS'
export const GET_ATTENDANCE_FAIL = 'GET_ATTENDANCE_FAIL'
export const SET_ATTENDANCE_SUCCESS = 'SET_ATTENDANCE_SUCCESS'
export const SET_ATTENDANCE_FAIL = 'SET_ATTENDANCE_FAIL'
export const UPDATE_ATTENDANCE_SUCCESS = 'UPDATE_ATTENDANCE_SUCCESS'
export const UPDATE_ATTENDANCE_FAIL = 'UPDATE_ATTENDANCE_FAIL'
export const STATUS_RESET = 'STATUS_RESET'
export const GET_SORTED_STUDENTS_SUCCESS = 'GET_SORTED_STUDENTS_SUCCESS'
export const ADD_SOLUTION_SUCCESS = 'ADD_SOLUTION_SUCCESS'
export const ADD_SOLUTION_FAIL = 'ADD_SOLUTION_FAIL'
export const ADD_MARK_SUCCESS = 'ADD_MARK_SUCCESS'
export const ADD_MARK_FAIL = 'ADD_MARK_FAIL'
export const GET_HOME_ADMITTANCEDATE_SUCCESS = 'GET_HOME_ADMITTANCEDATE_SUCCESS'
export const GET_HOME_ADMITTANCEDATE_FAIL = 'GET_HOME_ADMITTANCEDATE_FAIL'
export const GET_ABOUT_HISTORY_SUCCESS = 'GET_ABOUT_HISTORY_SUCCESS'
export const GET_ABOUT_HISTORY_FAIL = 'GET_ABOUT_HISTORY_FAIL'
export const GET_ABOUT_RATING_SUCCESS = 'GET_ABOUT_RATING_SUCCESS'
export const GET_ABOUT_RATING_FAIL = 'GET_ABOUT_RATING_FAIL'

