import React from 'react';

const Milestones = () => {
  return (
    <div>

    </div>
  );
};

export default Milestones;