import React, {useEffect, useState} from 'react';
import {MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBRow, MDBTypography} from "mdbreact";
import {connect} from 'react-redux';
import HomeProgramBlock from "./HomeProgramBlock";

import {load_mba} from '../../redux/actions/programs'

const ProgramsHomePage = ({load_mba, programs}) => {

  // const [progs, setProgs] = useState([])

  useEffect(() => {
    // load_categories();
    load_mba();
  }, [])

  return (
    <div>
      <MDBContainer className="my-5">
        <div className="home-titles">
          <h2>Программы MBA</h2>
        </div>
        <MDBRow className="">

          {programs?.length>0 && programs?.map((item) => (
            <HomeProgramBlock key={item.id} item={item}/>
          ))}

        </MDBRow>
      </MDBContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  programs: state.programs.mba,
});

export default connect(mapStateToProps, {load_mba})(ProgramsHomePage);