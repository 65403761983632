import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {load_home_blog} from '../redux/actions/home';
import HomeBlog from "../components/home/HomeBlog";
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow} from "mdbreact";

const Blog = ({blog, load_home_blog}) => {

  useEffect(() => {
    load_home_blog()
  }, [])

  const createMarkup = (html) => {
    return {__html: html};
  }

  return (
    <React.Fragment>
      <MDBContainer>
        <div style={{marginTop: 148}}>
          <MDBCard reverse>
            <MDBCardBody cascade className='p-0 mb-5 mx-3'>
              <div className='d-flex flex-column flex-lg-row align-items-center align-items-lg-start pt-5 pt-lg-0'>
                <img src={blog.image} alt="" width="350px"/>
                <div className='m-3'>
                  <h1>{blog.sub1}</h1>
                  <div>Автор: {blog.sub2}</div>
                  <div>Коуч по управленческим навыкам для предпринимателей и топ-менеджеров</div>
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
          <div className='mb-5 mx-3' dangerouslySetInnerHTML={createMarkup(blog.body)}/>
        </div>
      </MDBContainer>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  blog: state.home.home_blog
})

export default connect(mapStateToProps, {load_home_blog})(Blog);