import axios from "axios";

import {
  GET_PAGES_SUCCESS,
  GET_PAGES_FAIL,
  GET_SUBSECTIONS_SUCCESS,
  GET_SUBSECTIONS_FAIL,
} from './types'

export const load_pages = () => async dispatch => {
  const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/pages/`, config);

      dispatch({
        type: GET_PAGES_SUCCESS,
        payload: res.data
      })
    } catch (err) {
      dispatch({
        type: GET_PAGES_FAIL
      })
    }
}


export const load_subsections = () => async dispatch => {
  const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/subsection/`, config);

      dispatch({
        type: GET_SUBSECTIONS_SUCCESS,
        payload: res.data
      })
    } catch (err) {
      dispatch({
        type: GET_SUBSECTIONS_FAIL
      })
    }
}
