import React, {useEffect, useRef, useState} from "react";
import {
  MDBTimeline,
  MDBTimelineStep,
  MDBIcon,
  MDBCol,
  MDBCard,
  MDBView,
  MDBMask,
  MDBCardBody,
  MDBRow,
  MDBContainer
} from "mdbreact";

import {connect} from "react-redux";

const HistorySection = ({page, history}) => {

  const image_block = useRef(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [subsections, setSubsections] = useState([])
  const [image, setImage] = useState('')
  const [text, setText] = useState('')
  const [sub1, setSub1] = useState('')
  const [sub2, setSub2] = useState('')
  const [body, setBody] = useState('')

  useEffect(() => {
    if (image_block.current) {
      setWidth(image_block.current.offsetWidth)
      if (width > 0) {
        setHeight(width / 2)
      }
    }
  })

  useEffect(() => {
    if(page){
      setSubsections(page.subsections)
    }
  }, [page])

  useEffect(() => {
    image_block.current.height = height
  }, [height])

  useEffect(() => {
    if (subsections) {
      subsections.map(subsection => {
        if (subsection.title === 'История') {
          setImage(subsection.image)
          setText(subsection.excerption)
          setSub1(subsection.sub1)
          setSub2(subsection.sub2)
          setBody(subsection.body)
        }
      })
    }
  }, [subsections])

  return (
    <React.Fragment>

      <MDBRow className="">
        <MDBCol md="12">

          <div className='px-5'>
            <div
              ref={image_block}
              className="z-depth-2 position-relative"
              data-test="view"
              style={{
                backgroundImage: 'url(' + image + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: height,
                zIndex: '2',
                borderRadius: '.25rem',
                // boxShadow: '0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%)',
              }}
            >

              <MDBRow className='mx-auto justify-content-end' style={{maxWidth: 1200}}>
                  <MDBCol size='6' className='d-flex align-items-center justify-content-center' style={{marginTop: 90}}>
                    <div className='d-none d-lg-flex flex-column'>
                      <h3 className='mission-text'>{text}</h3>
                      {sub1 || sub2 &&
                        <div className='mission-footer d-inline'><p className='d-inline'>{sub1 ? sub1 : ''} <span>{sub2 ? sub2 : ''}</span></p>
                      </div>
                      }
                    </div>
                  </MDBCol>
                </MDBRow>
            </div>
          </div>

          <div
            className='z-depth-1 idab'
            style={{
              width: '100vw',
              padding: '4rem',
              margin: '-1rem'
            }}
          >
            <h2 className="white-text text-center font-weight-bolder">
              Наша история
            </h2>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBContainer className=''>
        {body && <MDBCard className='p-5'>
          <MDBCardBody>
            <div
              dangerouslySetInnerHTML={{__html: body}}
            />
          </MDBCardBody>

        </MDBCard>}
        <div className="">
          <MDBTimeline clasName="">
            {history?.map((item, index) => (
              <MDBTimelineStep
                inverted={(index+1)%2===0}
                colorful
                hoverable
                color="idab-3"
                label={index+1}
              >
              <img src={item?.image} className="img-fluid" alt=""/>
              <h4 className="font-weight-bold p-4 mb-0">
                {item?.title}
              </h4>
              <p className="text-muted px-4 mb-0">
                <MDBIcon icon="clock"/> {item?.date}
              </p>
              <p className="mb-0 p-4">
                {item?.text}
              </p>
            </MDBTimelineStep>
            ))}


          </MDBTimeline>
        </div>
      </MDBContainer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  page: state.pages.pages[1],
  history: state.about.about_history
})

export default connect(mapStateToProps)(HistorySection);